import request from "@/utils/request";

// 固渣统计
export function solid_all(areaCode, query) {
  return request({
    url: "/bi/residue/stat/all/" + areaCode,
    method: "GET",
    params: query
  });
}

// 收运情况
export function solid_collect(areaCode, query) {
  return request({
    url: "/bi/residue/stat/collect/" + areaCode,
    method: "GET",
    params: query
  });
}

// 送厂情况
export function solid_factory(areaCode, query) {
  return request({
    url: "/bi/residue/stat/factory/" + areaCode,
    method: "GET",
    params: query
  });
}
