<template>
  <div>
    <div class="time-flex">
      日期范围<el-date-picker
        v-model="timeValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="small"
        value-format="yyyy-MM-dd"
        @change="timeChange"
      >
      </el-date-picker>
      <div
        class="btn-time"
        :class="colorActive == 3 ? '' : 'btn-time-active'"
        @click="monthClick(3)"
      >
        近3月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 6 ? '' : 'btn-time-active'"
        @click="monthClick(6)"
      >
        近6月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 12 ? '' : 'btn-time-active'"
        @click="monthClick(12)"
      >
        近一年
      </div>
    </div>

    <div class="mying-box">
      <div class="mying-box-title">固渣统计</div>
      <div>
        <div class="process-box">
          <div class="process-box-item"></div>
          <div class="process-box-item">
            <img
              class="xian"
              src="@/assets/images/statistical/green.png"
              alt=""
            />
            <div class="process-box-text bgimg1">
              <div class="num">
                {{ solid.num1 }} <span class="num-sec">吨</span>
              </div>
              <div>未收运</div>
            </div>
            <div class="car car1">
              <div>已收运{{ solid.num4 }}吨</div>
              <img src="@/assets/images/statistical/car.png" alt="" />
            </div>
          </div>
          <div class="process-box-item">
            <img
              class="xian"
              src="@/assets/images/statistical/yellow.png"
              alt=""
            />
            <div class="process-box-text bgimg2">
              <div class="num">
                {{ solid.num2 }} <span class="num-sec">吨</span>
              </div>
              <div>未送厂</div>
            </div>
            <div class="car car2">
              <div>已送厂{{ solid.num5 }}吨</div>
              <img src="@/assets/images/statistical/car.png" alt="" />
            </div>
          </div>
          <div class="process-box-item">
            <img
              class="xian"
              src="@/assets/images/statistical/blue.png"
              alt=""
            />
            <div class="process-box-text bgimg3">
              <div class="num">
                {{ solid.num3 }} <span class="num-sec">吨</span>
              </div>
              <div>未处置</div>
            </div>
            <div class="car car3">
              <div>已处置{{ solid.num6 }}吨</div>
              <img src="@/assets/images/statistical/car.png" alt="" />
            </div>
          </div>
        </div>
        <div style="text-align:center">{{ solid.time }}的流向</div>
      </div>
    </div>
    <div class="mying-box">
      <div class="mying-box-title">收运情况</div>
      <!-- <div v-if="tableData.length !== 0"> -->
      <div class="form-search flex-v-center">
        <el-form
          :inline="true"
          :model="formSelect2"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="收运类型">
            <el-select
              v-model="formSelect2.type"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索">
            <el-input
              v-model="formSelect2.seach"
              clearable
              placeholder="请输入商户名称/收运单位"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search2">查询</el-button>
          </el-form-item>
        </el-form>
        <div>导出报表</div>
      </div>
      <div>
        <el-table :data="tableData2.list" style="width: 100%">
          <el-table-column prop="collectTime" label="收油时间">
          </el-table-column>
          <el-table-column prop="merchantName" label="商户名称">
          </el-table-column>
          <el-table-column prop="bizType" label="经营类型"> </el-table-column>
          <el-table-column prop="weight" label="重量/kg"> </el-table-column>
          <el-table-column prop="area" label="所属区域"> </el-table-column>
          <el-table-column prop="collectorName" label="收运单位">
          </el-table-column>
          <el-table-column prop="employeeName" label="收运人员">
          </el-table-column>
        </el-table>
        <div class="flex-v-center" style="margin-top: 10px">
          <div></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData2.total"
            :current-page="tableData2.currentpage"
            :page-size="tableData2.pagesize"
            @current-change="handleCurrentChange2"
            prev-text="上一页"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- </div>
      <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div> -->
    </div>
    <div class="mying-box">
      <div class="mying-box-title">送厂情况</div>
      <!-- <div v-if="tableData.length !== 0"> -->
      <div class="form-search flex-v-center">
        <el-form
          :inline="true"
          :model="formSelect3"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="收运类型">
            <el-select
              v-model="formSelect3.type"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索">
            <el-input
              v-model="formSelect3.seach"
              clearable
              placeholder="请输入收运单位/处置企业"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search3">查询</el-button>
          </el-form-item>
        </el-form>
        <div>导出报表</div>
      </div>
      <div>
        <el-table :data="tableData3.list" style="width: 100%">
          <el-table-column prop="factoryTime" label="送厂日期">
          </el-table-column>
          <el-table-column prop="collectorName" label="收运单位">
          </el-table-column>
          <el-table-column prop="employeeName" label="运输人员">
          </el-table-column>
          <el-table-column prop="weight" label="重量/kg"> </el-table-column>
          <el-table-column prop="factoryName" label="处置企业">
          </el-table-column>
        </el-table>
        <div class="flex-v-center" style="margin-top: 10px">
          <div></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData3.total"
            :current-page="tableData3.currentpage"
            :page-size="tableData3.pagesize"
            @current-change="handleCurrentChange3"
            prev-text="上一页"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- </div>
      <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  solid_all,
  solid_collect,
  solid_factory
} from "@/api/solidslag_statistics";
export default {
  data() {
    return {
      query: {
        offsetMonth: 3,
        beginDate: "",
        endDate: ""
      },
      areaCode: "",
      timeValue: "",
      colorActive: 3,
      selectList: [
        {
          label: "火锅",
          value: 1
        },
        {
          label: "中餐",
          value: 2
        },
        {
          label: "食堂",
          value: 3
        },
        {
          label: "西餐",
          value: 4
        },
        {
          label: "面食",
          value: 5
        },
        {
          label: "小吃",
          value: 6
        },
        {
          label: "料理",
          value: 7
        },
        {
          label: "其他",
          value: 8
        }
      ],
      solid: {
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num5: 0,
        num6: 0,
        time: ""
      },
      formSelect: {},
      formSelect2: {
        type: "",
        seach: ""
      },
      param2: {},
      tableData2: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      },
      formSelect3: {
        type: "",
        seach: ""
      },
      param3: {},
      tableData3: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      }
    };
  },
  mounted() {
    this.monthClick(3);
  },
  methods: {
    timeChange(e) {
      if (e != null) {
        this.query = {
          beginDate: e[0],
          endDate: e[1]
        };
        this.colorActive = 0;
        this.get_solid_all(this.areaCode, this.query);
        this.get_solid_collect();
        this.get_solid_factory();
      } else {
        this.colorActive = 3;
        this.monthClick(3);
      }
    },
    monthClick(data) {
      this.colorActive = data;
      this.timeValue = "";
      this.query = {
        offsetMonth: data
      };
      this.get_solid_all(this.areaCode, this.query);
      this.get_solid_collect();
      this.get_solid_factory();
    },
    get_solid_all(areaCode, query) {
      solid_all(areaCode, query).then(res => {
        this.solid = {
          num1: res.data.collectResidue,
          num2: res.data.disposalResidue,
          num3: res.data.factoryResidue,
          num4: res.data.notCollectResidue,
          num5: res.data.notDisposalResidue,
          num6: res.data.notToFactoryResidue,
          time: res.data.beginDate + "至" + res.data.endDate
        };
      });
    },
    get_solid_collect() {
      this.param2 = this.query;
      this.param2.pageSize = this.tableData2.pagesize;
      this.param2.pageNum = this.tableData2.currentpage;
      this.param2.isAsc = "asc";
      solid_collect(this.areaCode, this.param2).then(res => {
        // console.log(res);

        this.tableData2.list = res.rows;
        this.tableData2.total = res.total;
      });
    },
    handleCurrentChange2(val) {
      this.tableData2.currentpage = val;
      this.get_solid_collect();
    },
    search2() {
      this.param2.bizType = this.formSelect2.type;
      this.param2.condition = this.formSelect2.seach;
      this.get_solid_collect();
    },
    get_solid_factory() {
      this.param3 = this.query;
      this.param3.pageSize = this.tableData3.pagesize;
      this.param3.pageNum = this.tableData3.currentpage;
      this.param3.isAsc = "asc";
      solid_factory(this.areaCode, this.param3).then(res => {
        console.log(res);

        this.tableData3.list = res.rows;
        this.tableData3.total = res.total;
      });
    },
    handleCurrentChange3(val) {
      this.tableData3.currentpage = val;
      this.get_solid_factory();
    },
    search3() {
      this.param3.bizType = this.formSelect3.type;
      this.param3.condition = this.formSelect3.seach;
      this.get_solid_factory();
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.flex-v-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mying-box {
  padding: 20px;
  background: rgba(54, 59, 110, 0.3);
  border: 1px solid #3d4283;
  border-radius: 5px;
  margin-bottom: 20px;
}
.mying-box-title {
  font-size: 18px;
  color: rgba(93, 229, 245, 1);
  margin-bottom: 20px;
}
.process-box {
  width: 100%;
  height: 280px;
  display: flex;
  margin-bottom: 30px;
  // margin-left: -10%;
}
.process-box-item {
  width: 30%;
  position: relative;
}
.process-box-item:first-child {
  width: 10%;
}

.process-box-item:after {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to left,
    #00ccff 0%,
    #00ccff 50%,
    transparent 50%
  );
  background-size: 8px 1px;
  background-repeat: repeat-x;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
}
.xian {
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 75%;
  z-index: 1;
}
.process-box-text {
  width: 180px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  position: absolute;
  top: 0;
  left: -80px;
  padding-left: 80px;
  padding-bottom: 1.389vh;
}
.bgimg1 {
  background: url("~@/assets/images/statistical/kuai_1.png") no-repeat;
  background-size: contain;
}
.bgimg2 {
  background: url("~@/assets/images/statistical/kuai_2.png") no-repeat;
  background-size: contain;
}
.bgimg3 {
  background: url("~@/assets/images/statistical/kuai_3.png") no-repeat;
  background-size: contain;
}
.num {
  font-size: 1.375rem;
}
.num-sec {
  font-size: 0.875rem;
}
// infinite
.car {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.car1 {
  left: 0;
  animation: example 10s infinite;
}
.car2 {
  left: 0;
  animation: example 10s infinite;
}
.car3 {
  left: 0;
  animation: example 10s infinite;
}
@keyframes example {
  from {
    // left: 0;
    padding-left: 0;
  }
  to {
    // left: calc(100% - 100px);
    padding-left: calc(99% - 100px);
  }
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 20px;
  }
}
.time-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.btn-time {
  background: radial-gradient(#3c427d, #666ca7);
  border: 1px solid #666ca7;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}
.btn-time:hover {
  color: #ccc;
  border: 1px solid rgba($color: #666ca7, $alpha: 0.8);
}
.btn-time-active {
  background: #191c3c;
  border: 1px solid #323877;
}
.el-range-editor {
  margin: 0 20px;
}
</style>
